<template>
    <div class="tw-bg-white tw-shadow-glow tw-p-8 tw-text-center">
        <span class="tw-heading-5">{{ $t('project.detail.subtitles.hr_need_info') }} 🤔</span>
        <p class="tw-text-sm tw-mt-4">
            {{ $t('project.detail.descriptions.hr_contact_directly') }}
        </p>
        <div v-if="hr" class="tw-mt-6">
            <div class="tw-flex tw-items-center tw-justify-between tw-max-w-sm tw-mx-auto">
                <div class="tw-flex tw-items-center">
                    <img v-if="hr.photoURL" class="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-bg-black tw-rounded-full tw-mr-4" :src="hr.photoURL" />
                    <p class="tw-text-gray-800 tw-font-bold">{{ hr.displayName }}</p>
                </div>
                <div class="tw-flex tw-items-center tw-space-x-1">
                    <a
                        v-if="hr.phoneNumber"
                        :href="`tel:${hr.phoneNumber}`"
                        class="tw-inline-flex tw-w-10 tw-h-10 tw-rounded-full tw-bg-green-alt tw-items-center tw-justify-center tw-text-green hover:tw-bg-green-alt-2 tw-transition tw-duration-150 tw-no-underline"
                    >
                        <k-icon size="18" name="phone" class="tw-flex-shrink-0" />
                    </a>
                    <a
                        v-if="hr.email"
                        :href="`mailto:${hr.email}`"
                        class="tw-inline-flex tw-w-10 tw-h-10 tw-rounded-full tw-bg-gray-100 tw-items-center tw-justify-center tw-text-gray-800 hover:tw-bg-gray-200 tw-transition tw-duration-150 tw-no-underline"
                    >
                        <k-icon size="16" name="email" class="tw-flex-shrink-0" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import urlcat from "urlcat";

import config from "@shared/config";

export default {
    props: ["uid", "lang"],
    data() {
        return {
            hr: null,
            loading: false
        };
    },
    mounted() {
        this.fetchHr(this.uid);
    },
    methods: {
        async fetchHr(uid) {
            try {
                this.loading = true;
                const response = await fetch(urlcat(config.adminUrl, "api/external/hr/:uid", { uid }));
                const { data } = (await response.json()) || {};
                this.hr = data;
                this.loading = false;
            } catch (e) {
                console.error(e);
                this.loading = false;
            }
        }
    }
};
</script>
