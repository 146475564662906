<template>
    <div class="tw-mx-auto tw-w-full tw-text-gray-700">
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-8 lg:tw-space-y-0 lg:tw-space-x-8">
            <div class="tw-w-full">
                <section v-if="done" class="tw-bg-white tw-p-4 lg:tw-p-8 tw-text-center">
                    <img src="/web/projects/ok.svg" alt="finished" />
                    <h2 class="tw-heading-4 tw-leading-none">{{ $t("global.titles.all_done") }}</h2>
                    <p class="tw-mt-4 tw-px-12">
                        {{ $t("project.detail.descriptions.interest_team_contacts_you") }}
                    </p>
                    <div class="tw-mt-8 tw-flex tw-justify-center">
                        <k-button color="primary" class="tw-w-60" @click="$emit('close')">
                            {{ $t("global.ok") }}
                        </k-button>
                    </div>
                </section>

                <section v-else class="tw-bg-white tw-p-4 lg:tw-p-8">
                    <h2 class="tw-heading-4 tw-leading-none">{{ $t("project.detail.subtitles.interest_contacts") }}</h2>

                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-gap-6 sm:tw-grid-cols-2 tw-mt-6">
                        <k-input
                            autofocus
                            :title="$t('global.inputs.first_name')"
                            required
                            v-model="interest.firstName"
                            :invalid="submitted && !isValidField('firstName')"
                        />
                        <k-input :title="$t('global.inputs.last_name')" required v-model="interest.lastName" :invalid="submitted && !isValidField('lastName')" />
                    </div>

                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-gap-6 sm:tw-grid-cols-2 tw-mt-6">
                        <k-input
                            type="email"
                            :title="$t('global.inputs.email')"
                            required
                            v-model="interest.email"
                            :invalid="submitted && !isValidField('email')"
                        />
                        <k-input type="phone" :title="$t('global.inputs.phone')"  v-model="interest.phoneNumber" />
                    </div>

                    <div class="tw-mt-8">
                        <p class="tw-heading-5 tw-leading-none tw-mt-8">{{ $t("project.detail.subtitles.interest_upload_resume") }}</p>
                        <p class="tw-mt-2">{{ $t("project.detail.descriptions.interest_recommended_upload") }}</p>
                        <v-radio-group v-model="uploadCvNow" row class="tw-m-0 tw-mt-4 tw-p-0" hide-details>
                            <k-radio :label="$t('global.time.now')" :value="true" />
                            <k-radio :label="$t('global.time.later')" :value="false" @click="interest.files = undefined" />
                        </v-radio-group>
                    </div>
                    <v-expand-transition>
                        <div v-show="uploadCvNow" class="tw-mt-4">
                            <k-button @click="handleUploadFile()">
                                <k-icon name="attach" size="20" class="tw-mr-1" />
                                {{ $t("global.actions.upload_file") }}
                            </k-button>

                            <div v-if="interest.files" class="tw-mt-4">
                                <div class="tw-bg-gray-200 tw-px-2 tw-py-2 tw-rounded-sm tw-flex tw-items-center tw-justify-between">
                                    <span class="tw-pl-2">{{ interest.files[0].name }}</span>
                                    <k-button @click="interest.files = undefined">
                                        <k-icon name="close" size="14" class="tw-cursor-pointer" />
                                    </k-button>
                                </div>
                            </div>
                        </div>
                    </v-expand-transition>

                    <div class="tw-mt-8">
                        <p class="tw-heading-4 tw-leading-none tw-mt-8">{{ $t("gdpr.title") }}</p>
                        <k-gdpr
                            :lang="lang"
                            class="tw-mt-6"
                            v-model="interest.gdpr"
                            :invalid="submitted && !isValidField('gdpr')"
                            :gdprTooltip="$t('gdpr.descriptions.privacy_policy_tooltip')"
                            newsletterLabelText="gdpr.descriptions.project_interest_agree_to_contact"
                        />
                    </div>

                    <div class="tw-mt-8">
                        <k-button type="button" @click="submit">
                            {{ $t("global.actions.send") }}
                        </k-button>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["project", "lang"],
    data: () => ({
        uploadCvNow: true,
        submitted: false,
        done: false,
        interest: {
            firstName: undefined,
            lastName: undefined,
            phoneNumber: undefined,
            email: undefined,
            gdpr: undefined,
            files: undefined
        }
    }),
    computed: {
        profile() {
            return this.$store.state.USER.profile || null;
        }
    },
    watch: {
        profile: {
            immediate: true,
            handler() {
                if (this.profile && this.profile.email) {
                    this.interest.email = this.profile.email;
                }

                if (this.profile && this.profile.phoneNumber) {
                    this.interest.phoneNumber = this.profile.phoneNumber;
                }

                if (this.profile && this.profile.firstName) {
                    this.interest.firstName = this.profile.firstName;
                }

                if (this.profile && this.profile.lastName) {
                    this.interest.lastName = this.profile.lastName;
                }
            }
        }
    },
    methods: {
        submit() {
            this.submitted = true;
            if (this.isValidField("firstName") && this.isValidField("lastName") && this.isValidField("email") && this.isValidField("gdpr")) {
                this.$store.dispatch("PROJECTS/processInterest", {
                    projectId: this.project.id,
                    ...(this.interest || {})
                });

                window.location.hash = "#praca-hotovo";
                this.done = true;
            }
        },
        isValidField(fieldName) {
            switch (fieldName) {
                case "firstName":
                    return typeof this.interest.firstName === "string" && this.interest.firstName.trim().length > 0;
                case "lastName":
                    return typeof this.interest.lastName === "string" && this.interest.lastName.trim().length > 0;
                case "email":
                    return typeof this.interest.email === "string" && /(.+)@(.+){2,}\.(.+){2,}/.test(this.interest.email);
                case "gdpr":
                    return this.interest.gdpr && this.interest.gdpr.gdprValue === true;
            }
        },
        handleUploadFile() {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("name", "files");
            input.setAttribute("accept", ".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document");
            input.onchange = e => {
                this.interest.files = e.target.files;
            };
            input.dispatchEvent(new MouseEvent("click"));
        }
    }
};
</script>
