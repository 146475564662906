const LABELS = ["Frontend", "Backend", "Database", "Dev/Ops", "Style"];

const EXP_MAPPING = {
    "0-1": 1,
    "1": 2,
    "2": 3,
    "3": 4,
    "4": 5,
    "5-10": 7.5,
    "10+": 10
};

/**
 * Returns the index of the lable.
 *
 * @param {string} label
 * @returns {number}
 */
function getLableIndex(label) {
    return LABELS.indexOf(label);
}

/**
 *
 * @param {*} placeholder
 * @returns {number}
 */
function getExperienceValue(placeholder) {
    return EXP_MAPPING[placeholder] || 1;
}

export default function processExperiences(payload = [], availableTechnologiesByName = {}) {
    const data = Array(LABELS.length).fill(0);

    payload.forEach(item => {
        const { radarInfo } = availableTechnologiesByName[item.name] || {};

        if (radarInfo && Array.isArray(radarInfo)) {
            radarInfo.forEach(radarItem => {
                const labelIdx = getLableIndex(radarItem.cat);

                if (labelIdx > -1) {
                    let expPoints = radarItem.modifier * Math.log(2 + getExperienceValue(item.experience)) * 7;
                    if (data[labelIdx] + expPoints < 100) {
                        data[labelIdx] += expPoints;
                    }
                }
            });
        }
    });

    return data;
}
