<template>
    <!-- Skeleton -->
    <div v-if="!project"></div>

    <!-- Project -->
    <k-layout v-else class="tw-bg-white">
        <div v-if="!isPublished" class="tw-bg-red-alt tw-py-3">
            <k-container>
                <div class="tw-w-full tw-flex tw-items-center tw-text-red">
                    <k-icon name="info" class="tw-flex-shrink-0" />
                    <p class="tw-font-bold tw-ml-2">
                        {{ $t("project.detail.status.not_available") }}
                    </p>
                </div>
            </k-container>
        </div>

        <k-container class="tw-mt-12" hide-aside-mobile>
            <template #default>
                <div class="tw-flex tw-flex-col tw-items-center tw-space-y-1 sm:tw-flex-row sm:tw-space-y-0 sm:tw-space-x-2">
                    <span v-if="project.isNew" class="tw-rounded-sm tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-green-alt tw-text-green">
                        {{ $t("project.detail.status.new") }}
                    </span>
                    <span v-if="project.remote" class="tw-rounded-sm tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-yellow-alt tw-text-yellow">
                        {{ $t("project.detail.status.remote") }} {{ project.remotePercent }}%
                    </span>
                    <span v-if="project.languageTag" class="tw-rounded-sm tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-red-alt tw-text-red">
                        {{ project.languageTag }}
                    </span>
                </div>

                <h1 class="tw-heading-1 tw-mt-2">{{ project.name }}</h1>

                <template v-if="project.client && project.client.industry">
                    <p class="tw-mt-2">{{ project.client.industry }}</p>
                </template>

                <dl class="tw-mt-8 tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-shadow-glow">
                    <div class="tw-p-4">
                        <dt class="tw-font-semibold tw-text-sm tw-text-gray-500">{{ $t("project.detail.salary") }}</dt>
                        <dd class="tw-text-base tw-mt-2 tw-flex tw-flex-col">
                            <p v-if="formatRateContract" class="tw-inline-flex tw-items-center tw-space-x-1">
                                {{ formatRateContract }}
                                <v-tooltip v-if="project.rateContractNote" top max-width="600" color="rgba(0, 0, 0, 0.75)">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="tw-flex tw-items-center">
                                            <k-icon name="info" class="tw-text-gray-900" size="20" />
                                        </span>
                                    </template>
                                    <p>
                                        {{ project.rateContractNote }}
                                    </p>
                                </v-tooltip>
                            </p>
                            <p v-if="formatRateTPP" class="tw-inline-flex tw-items-center tw-space-x-1">
                                {{ formatRateTPP }}
                                <v-tooltip v-if="project.rateTPPNote" top max-width="600" color="rgba(0, 0, 0, 0.75)">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="tw-flex tw-items-center">
                                            <k-icon name="info" class="tw-text-gray-900" size="20" />
                                        </span>
                                    </template>
                                    <p>
                                        {{ project.rateTPPNote }}
                                    </p>
                                </v-tooltip>
                            </p>
                            <span v-if="!formatRateContract && !formatRateTPP">–</span>
                        </dd>
                    </div>

                    <div class="tw-p-4 tw-border-gray-100 tw-border-t md:tw-border-l md:tw-border-t-0">
                        <dt class="tw-font-semibold tw-text-sm tw-text-gray-500">{{ $t("project.detail.location") }}</dt>
                        <dd class="tw-text-base tw-mt-2">{{ project.location || "–" }}</dd>
                    </div>

                    <div class="tw-p-4 tw-border-gray-100 tw-border-t md:tw-border-l md:tw-border-t-0">
                        <dt class="tw-font-semibold tw-text-sm tw-text-gray-500">{{ $t("global.experience") }}</dt>
                        <dd class="tw-text-base tw-mt-2">{{ project.computedPositionType || "–" }}</dd>
                    </div>
                </dl>

                <div class="tw-mt-4 tw-px-4 tw-flex tw-justify-between tw-items-center tw-mb-8">
                    <span class="tw-text-gray-500 tw-text-sm">
                        <span>{{ project.visits }} {{ $tc("project.detail.views", project.visits > 4 ? 2 : project.visits > 1 ? 1 : 0) }}</span>
                        <span v-if="applicantsCount">
                            • {{ applicantsCount }} {{ $tc("project.detail.candidates", applicantsCount > 4 ? 2 : applicantsCount > 1 ? 1 : 0) }}</span
                        >
                    </span>
                    <span class="tw-text-gray-500 tw-text-sm">{{ project.publishedAt | toRelativeDistance }}</span>
                </div>

                <div v-if="profile" v-show="match >= 50" class="tw-bg-green-alt tw-border-l-4 tw-border-green tw-p-4 tw-mb-4 tw-rounded-r-sm">
                    <p class="tw-font-bold tw-text-green tw-leading-6">{{ $t("project.detail.subtitles.good_news") }}, {{ profile.firstName }}! 🎉</p>
                    <p class="tw-text-green tw-leading-6">
                        {{ $t("project.detail.descriptions.useful_for_you") }}
                    </p>
                </div>

                <div
                    v-if="!hasUserCv"
                    class="tw-flex tw-pt-8 tw-pb-8 tw-pl-8 tw-pr-4 tw-bg-koderia tw-bg-gradient-to-bl tw-from-koderia tw-to-koderia-alt tw-relative tw-rounded-2xl"
                >
                    <div class="tw-w-full sm:tw-w-3/5 ">
                        <span
                            ><span class="tw-text-white tw-font-bold tw-text-xl">Koderia</span>
                            <span class="tw-text-white tw-text-xl">{{ $t("global.resume") }}</span></span
                        >
                        <p class="tw-text-white tw-mt-2">
                            {{ $t("project.detail.descriptions.create_resume") }}
                        </p>

                        <k-link :to="{ name: 'cv-landing-page' }" class="tw-mt-8">
                            <k-button>
                                {{ $t("global.actions.create_resume") }}
                            </k-button>
                        </k-link>
                    </div>
                    <div class="tw-hidden sm:tw-block tw-w-2/5 tw-px-4 tw-h-full tw-absolute tw-right-0 tw-bottom-0">
                        <img class="tw-w-full tw-h-full tw-pl-4 tw-pt-4 tw-inset-0 tw-object-cover" src="./../assets/cv-teaser-new.svg" alt="" />
                    </div>
                </div>

                <section class="tw-rounded-sm tw-mt-16">
                    <div :class="{ 'tw-opacity-50': project.published === false }">
                        <h2 class="tw-heading-4 tw-text-gray-900">{{ $t("project.detail.subtitles.position") }}</h2>

                        <p id="project-description" class="tw-mt-6 tw-prose tw-max-w-full tw-text-gray-700 tw-leading-8" v-html="project.description"></p>

                        <ka-project-technologies
                            v-if="project.technologies && project.technologies.length"
                            :technologies="project.technologies"
                            :language="lang"
                            class="tw-mt-12"
                        />

                        <section class="tw-mt-12" v-if="project.requirements && project.requirements.length">
                            <p class="tw-heading-4 tw-leading-none">{{ $t("project.detail.requirements") }}</p>
                            <ul class="tw-list-none tw-p-0 tw-m-0 tw-space-y-4 tw-mt-6">
                                <li class="tw-flex tw-items-center" v-for="requirement in project.requirements" :key="requirement">
                                    <div class="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-border-koderia tw-border-2 tw-rounded-full"></div>
                                    <span class="tw-text-base tw-ml-2.5">
                                        {{ requirement }}
                                    </span>
                                </li>
                            </ul>
                        </section>

                        <section class="tw-mt-12" v-if="project.benefits && project.benefits.length">
                            <p class="tw-heading-4 tw-leading-none">{{ $t("project.detail.employer_benefits") }}</p>
                            <ul class="tw-list-none tw-p-0 tw-m-0 tw-space-y-4 tw-mt-6">
                                <li class="tw-flex tw-items-center" v-for="benefit in project.benefits" :key="benefit">
                                    <k-icon name="done" class="tw-flex-shrink-0 tw-text-koderia" size="24" />
                                    <span class="tw-text-base tw-ml-2">
                                        {{ benefit }}
                                    </span>
                                </li>
                            </ul>
                        </section>
                    </div>
                </section>
            </template>

            <template #aside>
                <template v-if="isPublished">
                    <div v-if="alreadyApplyForTheJob">
                        <div class="tw-bg-gray-100 tw-relative tw-rounded-sm tw-w-full tw-py-4 tw-text-center">
                            <span class="tw-text-gray-500 tw-font-bold tw-text-base">{{ $t("project.detail.status.already_responded") }}</span>
                        </div>
                        <p class="tw-mt-4 tw-text-center tw-w-4/5 tw-mx-auto">
                            {{ $t("project.detail.descriptions.team_contacts_you") }}
                        </p>
                    </div>
                    <div v-else class="tw-relative tw-rounded-sm">
                        <div class="tw-inset-0 tw-absolute tw-rounded-md tw-animate-btn-ping tw-w-full tw-h-full tw-bg-gray-300 tw-delay-1000"></div>
                        <k-button @click="isLogged ? (interestDialog = true) : doAuth('auth-signup')" class="tw-relative tw-w-full" id="gtm_apply_for_job">
                            <span class="tw-font-bold tw-text-base">{{ $t("project.detail.actions.respond_to_offer") }}</span>
                        </k-button>
                    </div>

                    <div class="tw-mt-6" v-if="project.assignedHR">
                        <project-detail-hr-contact-info :uid="project.assignedHR" :lang="lang" />
                    </div>
                </template>
            </template>
        </k-container>

        <k-container class="tw-mt-16 tw-mb-24">
            <template #default>
                <div class="tw-border-0 tw-border-t tw-border-gray-200 tw-border-solid tw-pt-16">
                    <dl class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-6">
                        <div>
                            <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t("project.detail.project_duration") }}</dt>
                            <dd class="tw-mt-2 tw-text-base">
                                <span v-if="project.duration">
                                    <span v-if="project.duration === 12">1 {{ $t("global.time.year") }}</span>
                                    <span v-else
                                        >{{ project.duration }} {{ $tc("global.time.months", project.duration > 4 ? 2 : project.duration > 1 ? 1 : 0) }}</span
                                    >
                                    <span v-if="project.durationExtension"> {{ $t("project.detail.duration_extension") }}</span>
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </dd>
                        </div>

                        <div>
                            <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t("project.detail.start_date") }}</dt>
                            <dd class="tw-mt-2 tw-text-base">
                                <span v-if="project.dateOfStartASAP">ASAP</span>
                                <span v-else>
                                    {{ project.dateOfStart | toLocaleDateString }}
                                </span>
                            </dd>
                        </div>

                        <div>
                            <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t("project.detail.type") }}</dt>
                            <dd class="tw-mt-2 tw-text-base">
                                <span v-if="project.tpp">{{ $t("project.detail.tpp") }}</span>
                                <span v-if="project.tpp && project.contract"> / </span>
                                <span v-if="project.contract">{{ $t("project.detail.contract") }}</span>
                            </dd>
                        </div>

                        <div>
                            <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t("project.detail.project_language") }}</dt>
                            <dd class="tw-mt-2 tw-text-base">
                                <span>{{ project.languageTag }}</span>
                            </dd>
                        </div>
                    </dl>
                </div>

                <div class="tw-mt-16" v-if="project.status === 'published' && !alreadyApplyForTheJob">
                    <div class="tw-relative">
                        <div class="tw-inset-0 tw-rounded-md tw-absolute tw-animate-btn-ping tw-w-full tw-h-full tw-bg-gray-300 tw-delay-1000"></div>
                        <k-button color="secondary" @click="isLogged ? (interestDialog = true) : doAuth('auth-signup')" class="tw-w-full" custom-class="tw-py-4" id="gtm_apply_for_job_2">
                            <span class="tw-font-bold tw-text-base">{{ $t("project.detail.actions.respond_to_offer") }}</span>
                        </k-button>
                    </div>

                    <div class="tw-mt-6" v-if="project.assignedHR">
                        <project-detail-hr-contact-info :uid="project.assignedHR" :lang="lang" />
                    </div>
                </div>
            </template>

            <template #aside>
                <template v-if="project.status === 'published'">
                    <div class="tw-flex tw-space-x-2">
                        <k-button type="button" @click.prevent="handleSave" v-if="profile">
                            <span>📨&nbsp;{{ isSaved ? $t("global.titles.saved") : $t("global.actions.save") }}</span>
                        </k-button>

                        <k-button :to="{ name: 'referral-form-project', params: { id: project.id } }" class="tw-w-full sm:tw-w-auto">
                            🕵️‍♀️&nbsp;{{ $t("global.actions.recommend") }}
                        </k-button>
                    </div>

                    <div class="tw-mt-8">
                        <p class="tw-font-bold tw-w-full tw-text-gray-800">{{ $t("global.actions.share") }}</p>
                        <div class="tw-space-x-2 tw-mt-4">
                            <a
                                :href="shareFacebookLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-full tw-text-gray-800 tw-inline-flex tw-items-center tw-no-underline tw-justify-center hover:tw-bg-gray-200 tw-transition tw-duration-150"
                            >
                                <k-icon size="16" name="facebook" />
                            </a>
                            <a
                                :href="shareLinkedInLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-full tw-text-gray-800 tw-inline-flex tw-items-center tw-no-underline tw-justify-center hover:tw-bg-gray-200 tw-transition tw-duration-150"
                            >
                                <k-icon size="16" name="linkedin" />
                            </a>
                            <a
                                :href="shareMailLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-full tw-text-gray-800 tw-inline-flex tw-items-center tw-no-underline tw-justify-center hover:tw-bg-gray-200 tw-transition tw-duration-150"
                            >
                                <k-icon size="16" name="email" />
                            </a>
                        </div>
                    </div>
                </template>
            </template>
        </k-container>

        <!-- Similar Jobs -->
        <section v-if="similarProjects && similarProjects.length > 0" class="tw-bg-gray-100 tw-mt-16 tw-pt-20 tw-pb-20">
            <k-container>
                <template #default>
                    <section>
                        <div class="tw-flex tw-justify-between tw-items-center">
                            <h1 class="tw-heading-3">{{ $t("project.detail.similar_job") }} 📌</h1>
                            <k-link :to="{ name: 'project-index' }" class="tw-text-gray-500 hover:tw-text-gray-700">
                                {{ $t("project.detail.actions.all_jobs") }} ›
                            </k-link>
                        </div>

                        <div class="tw-mt-8 tw-space-y-6">
                            <project-preview :project="project" v-for="project in similarProjects" :key="project.id" />
                        </div>
                    </section>
                </template>

                <template #aside>
                    <div class="tw-relative tw-overflow-hidden tw-bg-koderia tw-rounded-sm">
                        <img class="tw-absolute tw-w-full tw-inset-0 tw-z-0 tw-object-contain tw-object-center" src="./../assets/cv-teaser-bg-new.svg" alt="" />
                        <div class="tw-relative tw-flex tw-flex-col tw-pt-10 tw-pl-10 tw-pr-10">
                            <p class="tw-text-white tw-text-2xl tw-leading-tight">
                                <span v-html="$t('project.detail.descriptions.modern_resume')" />
                                <br />
                                <span class="tw-font-bold">{{ $t("project.detail.free") }}.</span>
                            </p>
                            <k-link :to="{ name: 'cv-landing-page' }" class="tw-no-underline tw-mt-8">
                                <k-button>
                                    {{ $t("global.actions.create_resume") }}
                                </k-button>
                            </k-link>
                            <img class="tw-w-full tw-h-full tw-object-cover tw-mt-8" src="./../assets/cv-teaser-new.svg" alt="" />
                        </div>
                    </div>
                </template>
            </k-container>
        </section>

        <v-dialog v-if="interestDialog" v-model="interestDialog" max-width="648">
            <project-detail-interest :project="project" @close="interestDialog = false" :lang="lang" />
        </v-dialog>
    </k-layout>
</template>

<script>
import Cookies from "js-cookie";
import urlcat from "urlcat";
import { mapGetters } from "vuex";

import KaProjectTechnologies from "@web/components/ka-project-technologies";
import processExperiences from "@web/utils/process-experiences";
import formatRate from "@web/utils/format-rate";
import config from "@shared/config";

import ProjectDetailHrContactInfo from "./../components/project-detail-hr-contact-info.vue";
import ProjectDetailInterest from "./../components/project-detail-interest.vue";
import ProjectPreview from "./../components/project-preview-new.vue";
import { DEFAULT_LOCALE } from "@web/constants/language";
import { formatDateDistance } from "@web/utils/date";
import KButton from "@web/components/core/k-button";

export default {
    components: {
        KButton,
        // KaLabel,
        // MatchMeter,
        // KaRadarWrapper,
        ProjectDetailHrContactInfo,
        KaProjectTechnologies,
        ProjectDetailInterest,
        ProjectPreview
    },
    data() {
        return {
            queryLang: undefined,
            applicantsCount: 0,
            interestDialog: false,
            cvTeaserVariant: Math.random(),
            showInterestDialog: false,
        };
    },
    created() {
        this.showInterestDialog = this.$route.hash === '#apply';

        const { id } = this.$route.params;
        this.$store.dispatch("NEW_PROJECTS/fetchProjectById", id).then(project => {
            if (!project) {
                this.notFound();
            } else {
                this.$store.dispatch("NEW_PROJECTS/visit", { id });
                this.fetchApplicantsCount();
            }
        });
        this.$vuetify.goTo(0);
    },
    watch: {
        "$route.query.lang": {
            immediate: true,
            handler(value) {
                this.queryLang = value;
            }
        },

        "project": {
            immediate: true,
            handler(project) {
                if (project && project.id) {
                    window.history.replaceState("", "", this.url);
                }
            }
        },

        "profile": {
            immediate: true,
            handler() {
                this.interestDialog = this.showInterestDialog;
            }
        }
    },
    computed: {
        isLogged() {
            return !!this.$store.state.AUTH.user;
        },
        isPublished() {
            return ["published", "hr"].includes(this.project.status);
        },
        lang() {
            if (this.queryLang) {
                return this.queryLang;
            }

            if (this.project && this.project.language) {
                return this.project.language;
            }

            return "sk";
        },
        appLocale() {
            return this.$store.getters.appLocale;
        },
        ...mapGetters("ENUMS", ["allTechnologiesIndexByName"]),
        ...mapGetters("NEW_PROJECTS", ["getProjectById", "allProjects"]),
        cv() {
            return this.$store.state.USER.userCv;
        },
        user() {
            return this.$store.state.AUTH.user;
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        alreadyApplyForTheJob() {
            const { id } = this.$route.params;
            const { projectInterests } = this.profile || {};

            return (projectInterests || []).includes(id);
        },
        hasUserCv() {
            return this.$store.state.USER.hasUserCv;
        },
        project() {
            const { id } = this.$route.params;
            return this.getProjectById(id);
        },
        isSaved() {
            if (!this.profile || !this.project) return false;
            return (this.profile.savedProjects || []).indexOf(this.project.id) !== -1;
        },
        experiences() {
            let experience = "3";

            const { technologies, positionType } = this.project || {};

            if (Array.isArray(positionType)) {
                if (positionType.indexOf("Senior") > -1) {
                    experience = "10+";
                } else if (positionType.indexOf("Medior") > -1) {
                    experience = "4";
                } else if (positionType.indexOf("Junior") > -1) {
                    experience = "1";
                }
            }

            const computedExperiences = (technologies || []).map(technology => ({
                name: technology,
                experience: experience
            }));

            return {
                experience: computedExperiences
            };
        },
        match() {
            const { experience: userExperience } = this.cv || {};
            const { experience: projectExperience } = this.experiences || {};

            const overallUserExperienceInSubject = processExperiences(userExperience, this.allTechnologiesIndexByName);
            const overallProjectExperienceInSubject = processExperiences(projectExperience, this.allTechnologiesIndexByName);

            let match = 0;
            const N = overallUserExperienceInSubject.length;
            const pointsPerSubject = 100 / overallProjectExperienceInSubject.filter(Boolean).length;
            const BONUS = pointsPerSubject * 0.05;

            for (let i = 0; i < N; i++) {
                const userPoints = overallUserExperienceInSubject[i];
                const projectPoints = overallProjectExperienceInSubject[i];
                if (projectPoints > 0 && userPoints > 0) {
                    const diff = userPoints / projectPoints;
                    if (diff > 1) {
                        match += pointsPerSubject + BONUS;
                    } else {
                        match += pointsPerSubject * diff;
                    }
                }
            }

            return match > 100 ? 100 : match;
        },
        similarProjects() {
            const { id, location, type } = this.project || {};
            return this.allProjects.filter(project => project.id !== id && project.location === location && project.type === type).slice(0, 5);
        },
        url() {
            const pathPrefix = this.appLocale === DEFAULT_LOCALE ? "" : `${this.appLocale}/`;
            const url = urlcat(window.location.origin, `${pathPrefix}${this.$t("route_alias.project_detail")}`, {
                id: this.project.id,
                ...(this.$route.query || {}),
                location: this.project.location,
                type: this.project.type,
                technologies: this.project.technologies
            });

            return url;
        },
        shareFacebookLink() {
            return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}`;
        },
        shareLinkedInLink() {
            return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(this.url)}`;
        },
        shareMailLink() {
            return `mailto:?subject=${this.project.name}&body=${this.project.name}%0A%0A${this.url}`;
        },
        formatRateContract() {
            return this.project && this.project.contract
                ? formatRate(this.project.rateContractOverride, this.project.rateContractFrom, this.project.rateContractTo, "€ / manday", this.lang)
                : "";
        },
        formatRateTPP() {
            return this.project && this.project.tpp
                ? formatRate(this.project.rateTPPOverride, this.project.rateTPPFrom, this.project.rateTPPTo, "€ / mesiac", this.lang)
                : "";
        }
    },
    filters: {
        toLocaleDateString(date) {
            try {
                return new Date(date).toLocaleDateString(this.appLocale);
            } catch {
                return "";
            }
        },
        toRelativeDistance(date) {
            try {
                return formatDateDistance(date);
            } catch {
                return "";
            }
        }
    },
    methods: {
        doAuth(type) {
            const { id } = this.$route.params;
            const redirectAfterLoginTo = {
                name: "project-detail",
                params: { id },
                hash: "#apply"
            };

            Cookies.set("redirect.after_login", JSON.stringify(redirectAfterLoginTo));

            this.$router.push({ name: type });
        },
        notFound() {
            const locale = this.appLocale === DEFAULT_LOCALE ? undefined : this.appLocale;
            this.$router.push({ name: "Page404", params: { "0": "", locale } }).catch(() => {});
        },

        async fetchApplicantsCount() {
            try {
                const response = await fetch(urlcat(config.adminUrl, "api/external/projects/:id/applicants", { id: this.project.id }));
                const { count } = (await response.json()) || {};
                this.applicantsCount = count || 0;
            } catch (err) {
                console.error(err);
            }
        },

        getProjectTeaser(project) {
            const { location, computedRate, computedPositionType } = project || {};

            if (location && computedRate && computedPositionType) {
                return `${location}, ${computedRate} · ${computedPositionType}`;
            }
            if (location && computedRate) {
                return `${location}, ${computedRate}`;
            }
            return `${computedRate}`;
        },

        handleSave() {
            if (this.isSaved) {
                this.$store.dispatch("USER/UPDATE_ENTITY", {
                    type: "remove",
                    entityName: "savedProjects",
                    entityId: this.project.id
                });
            } else {
                this.$store.dispatch("USER/UPDATE_ENTITY", {
                    type: "save",
                    entityName: "savedProjects",
                    entityId: this.project.id
                });
            }
        }
    }
};
</script>

<style lang="scss">
#project-description ul {
    padding: 0;
    list-style-type: none;
    list-style-position: inside;
}

#project-description h1,
#project-description h2,
#project-description h3,
#project-description h4,
#project-description h5,
#project-description h6 {
    font-size: 1.4rem;
    color: #111827 !important;
}

#project-description ul > li {
    padding-left: 1.875rem;
}

#project-description ul > li::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 9999px;
    border: 2px solid #0ea66d;

    // position the custom bullet in the middle of the first line
    top: 7px;

    // position the custom bullet in the middle of the whole list item element
    // top: 50%;
    // transform: translateY(-50%);
}
</style>
