<template>
    <dl class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-6">
        <div v-for="(cat, catIndex) in sortedUsedTechCategories" :key="catIndex">
            <dt class="tw-heading-6 tw-text-gray-500">{{ cat[computedLanguage] }}</dt>
            <dd class="tw-mt-4 tw-flow-root">
                <div class="tw--mt-0.5 tw--ml-0.5">
                    <k-technology-chip
                        size="lg"
                        v-for="technology in getAllUsedTechnologiesInCategory(cat.eng) || []"
                        :key="technology"
                        :technology="technology"
                        class="tw-m-1"
                    />
                </div>
            </dd>
        </div>
    </dl>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        language: {
            type: String,
            default: "sk"
        },
        technologies: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters("ENUMS", {
            allTechnologiesIndexByName: "allTechnologiesIndexByName"
        }),
        allTechnologyTypes() {
            return this.$store.state.ENUMS.technology_types;
        },
        usedFullTechnologies() {
            const returnValue = [];
            (this.technologies || []).forEach(technologyName => {
                const fullTechnology = this.allTechnologiesIndexByName[technologyName];
                // Known technology
                if (fullTechnology) {
                    returnValue.push(fullTechnology);
                }
                // Custom user-defined technology
                else {
                    returnValue.push({
                        category: "Other",
                        description: "",
                        name: technologyName
                    });
                }
            });

            return returnValue;
        },
        usedTechCategories() {
            const allCategories = (this.usedFullTechnologies || []).map(({ category }) => category);
            const uniqueCategories = [...new Set(allCategories)];
            return uniqueCategories;
        },
        sortedUsedTechCategories() {
            return (this.allTechnologyTypes || []).filter(({ eng }) => this.usedTechCategories.includes(eng));
        },
        computedLanguage() {
            if (this.language) {
                if (this.language == "sk" || this.language == "svk") {
                    return "svk";
                } else if (this.language == "en" || this.language == "eng") {
                    return "eng";
                }
            }
            return "svk";
        }
    },
    methods: {
        getAllUsedTechnologiesInCategory(category) {
            return this.usedFullTechnologies.filter(technology => technology.category == category).map(technology => technology.name);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.technologies__section {
    @media print {
        width: 100%;
        margin-top: 1.5rem;

        &:first-child {
            margin-top: 0;
        }
    }
}

.technologies {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    gap: 1.5rem;

    @include lg {
        grid-template-columns: 1fr 1fr;
    }

    @media print {
        display: block;
    }

    h6 {
        padding-bottom: 0.5em;
    }
}
</style>
